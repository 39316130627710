import * as React from 'react'

import { Popin } from 'components/common/popin/popin'
import { BoxBody, BoxHeader, BoxTitle, BoxFooter } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { useDispatch } from 'com.batch.common/react-redux'
import { confirmData } from 'com.batch/data-management/usecases/confirm-data'
import { showToast } from 'com.batch.redux/toaster'
import { DataKind } from 'com.batch/data-management/infra/types/data-kind.types'
import {
  ConfirmedCustomDataFactory,
  type ConfirmedCustomDataRecord,
} from 'com.batch/data-management/models/confirm-custom-data.record'
import { DataModalBox } from 'com.batch/data-management/ui/components/data-modal.styles'
import {
  newAttributesSelector,
  newEventsSelector,
} from 'com.batch/data-management/store/data-management.selectors'
import { useSelector } from 'react-redux'
import { type CustomDataRecord } from 'com.batch/data-management/models/custom-data.record'

type Props = {
  isOpen: boolean
  kind: DataKind
  onClose: () => void
}

export const ConfirmDataModal = ({ isOpen, kind, onClose }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const newEvents = useSelector(newEventsSelector)
  const newAttributes = useSelector(newAttributesSelector)
  const dispatch = useDispatch()

  const data = React.useMemo(() => {
    const dataToConfirm = kind === DataKind.ATTRIBUTE ? newAttributes : newEvents
    const result: ConfirmedCustomDataRecord[] = []
    dataToConfirm.map((att: CustomDataRecord) =>
      result.push(
        ConfirmedCustomDataFactory({
          name: att.name,
          kind: att.kind,
        })
      )
    )
    return result
  }, [kind, newAttributes, newEvents])

  const title = React.useMemo(
    () =>
      `Confirm ${data.length} new ${kind === DataKind.ATTRIBUTE ? 'attribute' : 'event'}${
        data.length > 1 ? 's' : ''
      }?`,
    [data.length, kind]
  )

  const handleCloseClick = React.useCallback(() => {
    onClose()
  }, [onClose])

  const handleConfirmClick = React.useCallback(() => {
    if (isLoading) return
    setIsLoading(true)
    dispatch(confirmData(data))
      .then(() => {
        dispatch(
          showToast({
            message: kind === DataKind.ATTRIBUTE ? 'Attributes confirmed' : 'Events confirmed',
            kind: 'success',
          })
        )
        onClose()
      })
      .catch(e => dispatch(showToast({ message: e, kind: 'error' })))
      .finally(() => setIsLoading(false))
  }, [data, dispatch, isLoading, kind, onClose])

  return (
    <Popin opened={isOpen} close={handleCloseClick}>
      <DataModalBox>
        <BoxHeader>
          <BoxTitle>{title}</BoxTitle>
          <Button onClick={handleCloseClick}>
            <Icon icon="close" />
          </Button>
        </BoxHeader>
        <BoxBody>
          <p>
            Please ensure all {kind === DataKind.ATTRIBUTE ? 'attributes' : 'events'} are ready.
          </p>
          <p>Once confirmed, you will be able to use them within your orchestrations.</p>
        </BoxBody>
        <BoxFooter>
          <Button onClick={handleCloseClick}>Cancel</Button>
          <Button intent="action" kind="primary" onClick={handleConfirmClick}>
            Yes, confirm
          </Button>
        </BoxFooter>
      </DataModalBox>
    </Popin>
  )
}
